import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from '../../images/icon.svg';

const handleClick = (ev) => {
  ev.preventDefault();
  // eslint-disable-next-line no-undef
  const element = document.querySelector('#nav');
  element.classList.toggle('block');
  element.classList.toggle('hidden');
};

const Header = ({ siteTitle }) => (
  <header className="sticky z-10 bg-white shadow-md pin-t">
    <div className="w-full max-w-3xl mx-auto lg:flex">
      <div className="flex items-center border-b lg:border-b-0">
        <div className="flex items-center justify-between w-full">
          <Link
            to="/"
            className="flex items-center px-4 no-underline xxl:pl-0 text-grey-darkest"
          >
            <img className="w-8 h-8 mr-2" src={Logo} alt="" />
            <span className="font-serif text-xl font-normal flex-no-shrink">
              {siteTitle}
            </span>
          </Link>

          <button
            className="flex items-center justify-center p-4 lg:hidden"
            onClick={handleClick}
            type="button"
          >
            <svg
              className="w-4 h-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
      </div>
      <nav
        id="nav"
        className="items-center hidden w-full border-b lg:border-none lg:flex lg:flex-1 lg:justify-end lg:w-auto"
      >
        <NavLink link="/accounting" title="Accounting" />
        <NavLink link="/small-business" title="Small&nbsp;Business" />
        <NavLink link="/taxation" title="Taxation" />
        <NavLink link="/financial-planning" title="Financial&nbsp;Planning" />
        <NavLink link="/contact" title="Contact" button />
      </nav>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const NavLink = ({ link, button, title }) => (
  <Link
    to={link}
    className={`hover:bg-green-600 hover:text-green-050 block lg:inline-flex border-b lg:border-0 font-semibold no-underline p-4 text-green-600 tracking-wide text-sm uppercase ${
      button
        ? 'button mb-2 lg:mb-0 ml-3 lg:ml-2 mr-4 xxl:mr-0 mt-2 lg:mt-0'
        : ''
    }`}
  >
    {title}
  </Link>
);

NavLink.propTypes = {
  link: PropTypes.string,
  button: PropTypes.bool,
  title: PropTypes.string,
};

export default Header;
