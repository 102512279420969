import '../../css/tailwind.css';
import '../../css/typography.css';
import '../../css/global.css';

import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Footer from '../Footer';
import Header from '../Header';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className="relative flex flex-col min-h-screen font-sans text-grey-darkest">
        <Helmet>
          <meta
            name="google-site-verification"
            // eslint-disable-next-line no-secrets/no-secrets
            content="p2mDrvqYsAoYJ3fABN1pOMzy2YzkW8rWNTpsol5j6K8"
          />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
