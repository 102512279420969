import PropTypes from 'prop-types';
import React from 'react';

import Dropbox from '../../images/mjs-dropbox.svg';
import FairWork from '../../images/mjs-fair-work.svg';
import GoogleDrive from '../../images/mjs-google-drive.svg';
import myGov from '../../images/mjs-my-gov.svg';
import MYOB from '../../images/mjs-myob.svg';
import Quickbooks from '../../images/mjs-quickbooks.svg';
import Xero from '../../images/mjs-xero.svg';

const Logins = () => (
  <ul className="list-reset">
    <Login
      image={Quickbooks}
      link="https://qbo.intuit.com/login"
      title="Quickbooks"
    />
    <Login image={Xero} link="https://login.xero.com/" title="Xero" />
    <Login
      image={MYOB}
      link="https://login.myob.com/Account/Login"
      title="MYOB"
    />
    <Login
      image={myGov}
      link="https://my.gov.au/LoginServices/main/login"
      title="myGov"
    />
    <Login
      image={FairWork}
      link="https://www.fairwork.gov.au/my-account/fwosignin.aspx"
      title="FairWork"
    />
    <Login image={Dropbox} link="http://dropbox.com/login" title="Dropbox" />
    <Login
      image={GoogleDrive}
      link="https://drive.google.com/drive/u/0/my-drive"
      title="Google Drive"
    />
  </ul>
);

const Login = ({ image, link, title }) => (
  <li className="flex items-center mb-2">
    <img className="mr-4 w-6" src={image} alt="" />
    <a
      className="text-white underline"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      {title}
    </a>
  </li>
);

Login.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default Logins;
