import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';

import Logins from '../FooterLogins';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            author
            phone
            phone_formatted
            email
          }
        }
      }
    `}
    render={(data) => (
      <footer className="w-full text-white bg-green-600 pin-b">
        {/* Main section */}
        <div className="w-full max-w-2xl mx-auto mb-12">
          <div className="w-full cards">
            {/* Navigation */}
            <div className="p-4 mx-auto">
              <h3>Site Navigation</h3>
              <hr className="mb-2 bg-green-100" />
              <ul>
                <li className="mb-2">
                  <Link className="text-white underline" to="/">
                    Home
                  </Link>
                </li>
                <li className="mb-2">
                  <Link className="text-white underline" to="/accounting/">
                    Accounting
                  </Link>
                </li>
                <li className="mb-2">
                  <Link className="text-white underline" to="/small-business/">
                    Small Business
                  </Link>
                </li>
                <li className="mb-2">
                  <Link className="text-white underline" to="/taxation/">
                    Taxation
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    className="text-white underline"
                    to="/financial-planning/"
                  >
                    Financial Planning
                  </Link>
                </li>
                <li className="mb-2">
                  <Link className="text-white underline" to="/contact/">
                    Contact
                  </Link>
                </li>
                <li className="mb-2">
                  <a className="text-white underline" href="/sitemap.xml">
                    Sitemap
                  </a>
                </li>
              </ul>
            </div>
            {/* Contact */}
            <div className="p-4 mx-auto">
              <h3>Contact</h3>
              <hr className="mb-2 bg-green-100" />
              {/* Phone */}
              <p className="flex items-center mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 mr-4"
                >
                  <path
                    className="text-green-400 fill-current"
                    d="M4 2h4a1 1 0 0 1 .98.8l1 5a1 1 0 0 1-.27.9l-2.52 2.52a12.05 12.05 0 0 0 5.59 5.59l2.51-2.52a1 1 0 0 1 .9-.27l5 1c.47.1.81.5.81.98v4a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2z"
                  />
                  <path
                    className="text-green-200 fill-current"
                    d="M14.7 10.7a1 1 0 0 1-1.4-1.4L17.58 5H15a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V6.41l-4.3 4.3z"
                  />
                </svg>
                <a
                  className="text-white underline"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phone_formatted}
                </a>
              </p>
              {/* Email */}
              <p className="flex items-center mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 mr-4"
                >
                  <path
                    className="text-green-200 fill-current"
                    d="M15.6 15.47A4.99 4.99 0 0 1 7 12a5 5 0 0 1 10 0v1.5a1.5 1.5 0 1 0 3 0V12a8 8 0 1 0-4.94 7.4 1 1 0 1 1 .77 1.84A10 10 0 1 1 22 12v1.5a3.5 3.5 0 0 1-6.4 1.97zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  />
                </svg>
                <a
                  className="text-white underline"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  {data.site.siteMetadata.email}
                </a>
              </p>
              {/* Address */}
              <p className="flex items-center mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 mr-4"
                >
                  <path
                    className="text-green-200 fill-current"
                    d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zM12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  />
                  <path
                    className="text-green-400 fill-current"
                    d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"
                  />
                </svg>
                <a
                  className="text-white underline"
                  href="https://goo.gl/maps/69hMRTZKCWL2"
                >
                  1/68 Clarence St,
                  <br />
                  Port Macquarie NSW 2444
                </a>
              </p>
              {/* Contact Form */}
              <p className="flex items-center mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 mr-4"
                >
                  <path
                    className="text-green-200 fill-current"
                    d="M5 5h14a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2zm3 7a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H8z"
                  />
                  <path
                    className="text-green-400 fill-current"
                    d="M15 4a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V6c0-1.1.9-2 2-2 0-1.1.9-2 2-2h2a2 2 0 0 1 2 2z"
                  />
                </svg>

                <Link className="text-white underline" to="/contact/">
                  Contact form
                </Link>
              </p>

              <div className="pt-16">
                <h3>Affiliated Companies</h3>
                <hr className="mb-2 bg-green-100" />
                <ul>
                  <li className="mb-2">
                    <a
                      className="text-white underline"
                      href="http://www.ccaba.com.au"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Coastal Commercial &amp; Business Agents
                    </a>
                  </li>
                  {/* <li className="mb-2">
                    <a
                      className="text-white underline"
                      href="http://www.lifespanfp.com.au/adviser/coastalfis/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Coastal Financial &amp; Insurance Services
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* Logins */}
            <div className="p-4 mx-auto">
              <h3>Logins</h3>
              <hr className="mb-2 bg-green-100" />
              <Logins />
            </div>
          </div>
        </div>

        {/* Byline */}
        <p className="flex flex-wrap items-center justify-center w-full p-4 mb-0 font-semibold text-center text-white bg-green-900">
          <span className="w-full flex-no-shrink md:w-auto">
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
          </span>
          <span className="hidden w-full mx-2 md:block md:w-auto">&bull;</span>
          <span className="w-full flex-no-shrink md:w-auto">
            <a
              className="inline-flex px-1 font-black text-white underline"
              href="https://pd.design"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed and developed by PD
            </a>
          </span>
        </p>

        {/* Legal requirements */}
        <p className="flex items-center justify-center pb-4 mb-0 text-xs font-semibold tracking-wide text-center uppercase bg-green-900">
          Authorised Representative 1244822 Bluewater Financial Advisers Pty Ltd
          AFSL 411846
        </p>

        {/* Back to top link */}
        <a
          href="#top"
          className="fixed items-end justify-center hidden w-10 h-10 mb-16 mr-10 leading-none no-underline bg-green-600 rounded shadow-lg opacity-75 md:flex pin-b pin-r"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="w-full h-full fill-current text-green-050"
          >
            <path
              className="text-white underline"
              fillRule="evenodd"
              d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
            />
          </svg>
        </a>
      </footer>
    )}
  />
);

export default Footer;
